import React from 'react'
import { Grid } from '@mui/material'
import Divider from '@mui/material/Divider'
import { Card, CardContent, ListItem, ListItemText, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import OutputIcon from '@mui/icons-material/Output'
import DateRangeIcon from '@mui/icons-material/DateRange'
import PaidIcon from '@mui/icons-material/Paid'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import CommentIcon from '@mui/icons-material/Comment'
import Span from '@jumbo/shared/Span'
import { formatDate } from 'devextreme/localization'

const RightInfoCard = ({ transaction }) => {
    return (
        <Grid>
            <Card sx={{ height: { xs: 'auto', md: 250 } }}>
                <CardContent>
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item sx={{ width: '45%', display: 'flex', alignItems: 'center', marginRight: '5%' }}>
                            {transaction.fbrResponse && (
                                <List sx={{ width: '100%' }}>
                                    <ListItem sx={{ mt: -1 }}>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant={'h5'}
                                                    color='text.primary'
                                                    fontFamily='Lobster, cursive'>
                                                    {' '}
                                                    <OutputIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                                    <Span sx={{ color: 'info.dark' }}>
                                                        {' '}
                                                        {transaction.fbrResponse}
                                                    </Span>{' '}
                                                    <Span sx={{ fontSize: 13 }}> fbr Response</Span>
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem sx={{ mt: -1 }}>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant={'h5'}
                                                    color='text.primary'
                                                    fontFamily='Lobster, cursive'>
                                                    {' '}
                                                    <PaidIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                                    <Span sx={{ color: 'info.dark' }}>
                                                        {' '}
                                                        {transaction.fbrTotalAmount}
                                                    </Span>{' '}
                                                    <Span sx={{ fontSize: 13 }}> fbr amount</Span>{' '}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem sx={{ mt: -1 }}>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant={'h5'}
                                                    color='text.primary'
                                                    fontFamily='Lobster, cursive'>
                                                    {' '}
                                                    <CreditCardIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                                    <Span sx={{ color: 'info.dark' }}>
                                                        {transaction.fbrServiceFees}
                                                    </Span>{' '}
                                                    <Span sx={{ fontSize: 13 }}> fbr fees</Span>{' '}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem sx={{ mt: -1 }}>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant={'h5'}
                                                    color='text.primary'
                                                    fontFamily='Lobster, cursive'>
                                                    {' '}
                                                    <CreditCardIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                                    <Span sx={{ color: 'info.dark' }}>
                                                        {transaction.fbrLastPubDate
                                                            ? formatDate(transaction.fbrLastPubDate)
                                                            : ''}
                                                    </Span>{' '}
                                                    <Span sx={{ fontSize: 13 }}> fbr last publish date </Span>{' '}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            )}
                        </Grid>

                        <Grid item sx={{ width: '45%', display: 'flex', alignItems: 'right', ml: 1.9 }}>
                            {' '}
                            <List sx={{ width: '100%' }}>
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <CommentIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Comments
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2, width: '100%' }} />
                                <ListItemText>
                                    <Typography variant={'h5'} color='secondary.dark'>
                                        {transaction.txnComments && (
                                            <pre>
                                                <Span
                                                    style={{
                                                        fontSize: '1rem',
                                                        color: 'secondary.dark',
                                                        fontFamily: 'Lobster, cursive',
                                                        fontWeight: '400',
                                                        lineHeight: 1.5,
                                                        whiteSpace: 'pre-wrap',
                                                    }}>
                                                    {transaction.txnComments + ' '}
                                                </Span>
                                            </pre>
                                        )}
                                    </Typography>
                                </ListItemText>
                            </List>
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default RightInfoCard
