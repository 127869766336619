import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useDispatch, useSelector } from 'react-redux'
import { getTransactionPaymentsList } from 'app/redux/store/features/transactions'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
    CustomPaging,
    PagingState,
    SortingState,
    SelectionState,
    SummaryState,
    IntegratedSummary,
    DataTypeProvider,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSummaryRow,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import { Paper, useMediaQuery } from '@mui/material'
import { CurrencyFormatterSummary } from 'app/utils/components/formatCurrency/formatCurrency'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import useJumboTheme from '../../../../../../../../../../@jumbo/hooks/useJumboTheme'

const TxnPaymentGrid = ({ txnPaymentsData, posTxId }) => {
    const pageSize = 10
    const [currentPage, setCurrentPage] = useState(0)
    const [filter, setFilter] = useState({
        posTxId: posTxId,
        onlineTxn: false,
    })
    const dispatch = useDispatch()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [sorting, setSorting] = useState([{ columnName: 'termYear', direction: 'asc' }])
    const dateColumns = ['paymentDate']
    const { loading, error } = useSelector((state) => state.transactions)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md
    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getTransactionPaymentsList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const columnName = sortOrder[0].columnName.charAt(0).toUpperCase() + sortOrder[0].columnName.slice(1)
        const currentFilter = {
            ...filter,
            sort: columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getTransactionPaymentsList(currentFilter))
        setFilter(currentFilter)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const CurrencyTypeProvider = (props) => (
        <DataTypeProvider formatterComponent={CurrencyFormatterSummary} {...props} />
    )

    const Cell = (props) => {
        let style = {
            height: '42px',
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const columns = [
        { name: 'paymentDate', title: 'Payment Date' },
        { name: 'paymentOption', title: 'Payment Option' },
        { name: 'paymentType', title: 'Payment Type' },
        { name: 'amount', title: 'Amount' },
    ]

    const tableColumnExtensions = useMemo(
        () => [
            { columnName: 'paymentDate', width: min ? '180px' : '25%', minWidth: '180px', wordWrapEnabled: true },
            { columnName: 'paymentOption', width: min ? '150px' : '25%', minWidth: '150px', wordWrapEnabled: true },
            { columnName: 'paymentType', width: min ? '150px' : '25%', minWidth: '150px', wordWrapEnabled: true },
            {
                columnName: 'amount',
                align: 'center',
                width: min ? '150px' : '25%',
                minWidth: '150px',
            },
        ],
        [min]
    )

    const totalSummaryItems = [{ columnName: 'amount', type: 'sum' }]

    const currencyColumns = ['amount']

    const messages = {
        sum: 'Total',
    }

    const [columnWidths, setColumnWidths] = useState(tableColumnExtensions)

    useEffect(() => {
        setColumnWidths(tableColumnExtensions)
    }, [min])

    return (
        <JumboCardQuick
            noWrapper
            headerSx={{
                height: 40,
                bgcolor: 'secondary.main',
                pl: 1,
            }}>
            <Paper style={{ position: 'relative' }}>
                <Grid rows={txnPaymentsData.data} columns={columns}>
                    <DateTypeProvider for={dateColumns} />
                    <CurrencyTypeProvider for={currencyColumns} />
                    <SortingState sorting={sorting} onSortingChange={onSortingChange} columnSortingEnabled={true} />
                    <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} />
                    <CustomPaging totalCount={txnPaymentsData.totalCount} />
                    <SummaryState totalItems={totalSummaryItems} />
                    <IntegratedSummary />
                    <Table columnExtensions={columnWidths} cellComponent={Cell} />
                    <TableHeaderRow showSortingControls />
                    <TableSummaryRow messages={messages} />
                    <SelectionState />
                    <PagingPanel />
                </Grid>
            </Paper>
        </JumboCardQuick>
    )
}

export default TxnPaymentGrid
