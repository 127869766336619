import React, { useEffect, useState } from 'react'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { DataTypeProvider, EditingState, SelectionState, SortingState } from '@devexpress/dx-react-grid'
import { Paper, Stack } from '@mui/material'
import {
    Grid,
    Table,
    TableEditRow,
    TableHeaderRow,
    TableInlineCellEditing,
    TableSelection,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import { useDispatch, useSelector } from 'react-redux'
import { getUserPermissionList, updateUserPermission } from 'app/redux/store/features/users'
import { useSnackbar } from 'notistack'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import CommitIcon from '@mui/icons-material/Commit'
import Checkbox from 'react-custom-checkbox'
import * as Icon from 'react-icons/fi'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'

const UserPermissionsGrid = ({ handleError, userId }) => {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const [enableButton, setEnabledButton] = useState(true)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { updateUserInProgress, userPermissions, loading, error } = useSelector((state) => state.users)
    const getRowId = (row) => row.permissionId
    const [CheckBoxColumns] = useState(['enabled'])
    const [sorting, setSorting] = useState([{ columnName: 'enabled', direction: 'asc' }])
    const [userPermission, setUserPermission] = useState({ data: [], totalCount: 0 })
    const pageSize = 10

    useEffect(() => {
        if (!apiCallInProgress || loading) return

        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [error, loading])

    useEffect(() => {
        if (!apiCallInProgress || updateUserInProgress) return
        reloadPermissions()
        hideDialogAndRefreshUserList()
    }, [updateUserInProgress])

    useEffect(() => {
        setApiCallInProgress(true)
        setEnabledButton(true)
        dispatch(
            getUserPermissionList({
                userId: userId,
            })
        )
    }, [dispatch])

    useEffect(() => {
        setUserPermission({ ...userPermissions })
    }, [userPermissions])

    const CheckBoxFormatter = ({ value }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Checkbox icon={<Icon.FiCheck size={20} />} size={20} borderColor={'#808080'} checked={value} />
            </div>
        )
    }

    const CheckBoxEditor = ({ value, onValueChange, autoFocus, onBlur }) => {
        const handleChange = (selectedOption) => {
            onValueChange(selectedOption)
            setTimeout(() => onBlur())
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Checkbox
                    icon={<Icon.FiCheck size={20} />}
                    size={20}
                    checked={value}
                    borderColor={'#808080'}
                    onChange={(e) => handleChange(e)}
                    autoFocus={autoFocus}
                    onBlur={onBlur}
                />
            </div>
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const CheckBoxTypeProvider = (props) => {
        return <DataTypeProvider formatterComponent={CheckBoxFormatter} editorComponent={CheckBoxEditor} {...props} />
    }

    const commitChanges = ({ changed }) => {
        if (changed) {
            setEnabledButton(false)
            const updatedUserPermissionsData = userPermission.data.map((row) => {
                const changedRow = changed[row.permissionId]
                return changedRow ? { ...row, ...changedRow } : row
            })
            setUserPermission((prevChanges) => {
                return {
                    ...prevChanges,
                    data: updatedUserPermissionsData,
                }
            })
        }
    }

    const onUpdatePermission = () => {
        const updatedUserPermission = { data: [] }
        userPermissions.data.map((data, index) => {
            if (userPermission.data[index].enabled !== userPermissions.data[index].enabled)
                updatedUserPermission.data.push({
                    userId: userPermission.data[index].userId,
                    permissionId: userPermission.data[index].permissionId,
                    enabled: userPermission.data[index].enabled,
                })
        })
        setApiCallInProgress(true)
        dispatch(updateUserPermission(updatedUserPermission, userId))
    }

    const reloadPermissions = () => {
        dispatch(
            getUserPermissionList({
                userId: userId,
                skip: 0,
                take: pageSize,
            })
        )
        setEnabledButton(true)
    }

    const onColumnSort = (sortOrder) => {
        const currentFilter = {
            sort: sortOrder[0].columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        reloadPermissions(currentFilter)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const Cell = (props) => {
        let style = {
            paddingTop: 10,
            paddingBottom: 10,
        }
        if (props.column.name === 'enabled')
            style = {
                ...style,
                textAlign: 'center',
            }
        else {
            style = {
                ...style,
                textAlign: 'left',
            }
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const handleSaveChanges = () => {
        showDialog({
            variant: 'confirm',
            title: 'This will update the selected User Permissions, do you want to continue?',
            onYes: () => {
                onUpdatePermission()
            },
            onNo: hideDialog,
        })
    }

    const hideDialogAndRefreshUserList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Permissions data updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const columns = [
        { name: 'enabled', title: 'Enabled' },
        { name: 'permissionName', title: 'Permission Name' },
    ]

    const columnWidths = [
        { columnName: 'enabled', width: '50%' },
        { columnName: 'permissionName', width: '50%' },
    ]

    return (
        <>
            <Paper style={{ position: 'relative' }}>
                <Grid rows={userPermission.data} columns={columns} getRowId={getRowId}>
                    <SortingState sorting={sorting} onSortingChange={onSortingChange} columnSortingEnabled={true} />
                    <Table
                        cellComponent={Cell}
                        columnExtensions={columnWidths}
                        noDataCellComponent={NoDataCellWithLoadingType}
                    />
                    <TableHeaderRow showSortingControls cellComponent={Cell} />
                    <CheckBoxTypeProvider for={CheckBoxColumns} />
                    <EditingState
                        onCommitChanges={commitChanges}
                        columnExtensions={[
                            { columnName: 'permissionName', editingEnabled: false },
                            { columnName: 'enabled', editingEnabled: true },
                        ]}
                    />
                    <TableInlineCellEditing startEditAction={'click'} selectTextOnEditStart={false} />
                    <TableEditRow />
                    <SelectionState />
                    <TableSelection selectByRowClick showSelectionColumn={false} />
                </Grid>
            </Paper>
            <Stack direction={'row'} spacing={3} sx={{ mt: 3, justifyContent: 'center', textAlign: 'center' }}>
                <LoadingButton
                    disabled={enableButton}
                    size={'small'}
                    type='submit'
                    onClick={handleSaveChanges}
                    variant={'contained'}>
                    <SaveIcon sx={{ fontSize: 18, mr: 1 }} /> Saved
                </LoadingButton>
                <LoadingButton
                    disabled={enableButton}
                    size={'small'}
                    type={'reset'}
                    onClick={() => reloadPermissions()}
                    variant={'outlined'}>
                    <CommitIcon sx={{ fontSize: 18, mr: 1 }} /> Discard
                </LoadingButton>
            </Stack>
        </>
    )
}

export default UserPermissionsGrid
