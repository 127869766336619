import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import PublishIcon from '@mui/icons-material/Publish'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import Link from '@mui/material/Link'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Paper, useMediaQuery } from '@mui/material'
import { CustomPaging, PagingState, SortingState, SelectionState, DataTypeProvider } from '@devexpress/dx-react-grid'
import {
    Grid,
    Table,
    TableHeaderRow,
    VirtualTable,
    PagingPanel,
    TableSelection,
} from '@devexpress/dx-react-grid-material-ui'
import { deleteBranch, getBranchById } from 'app/redux/store/features/branches'
import branchServices from 'app/services/settings/branch/branchServices'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import { useJumboTheme } from '@jumbo/hooks'

const BranchGridData = ({
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const { branches, loading, error } = useSelector((state) => state.branches)
    const { companyConfig } = useSelector((state) => state.companies)
    const dateColumns = ['licenseStartDate', 'licenseEndDate']
    const [sorting, setSorting] = useState([{ columnName: 'branchName', direction: 'asc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = useCallback(
        (id) => {
            dispatch(getBranchById({ branchId: id, companyId: companyConfig.companyId }))
            navigate(`/settings/companies/${companyConfig.companyId}/branches/edit/${id}`, {
                state: { branchId: id, companyId: companyConfig.companyId },
            })
        },
        [dispatch, navigate, companyConfig.companyId]
    )

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.branchId)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />
    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => onPublishBranch(row.branchId)}>
                    <PublishIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onNavigatingToDetail(row.branchId)}>
                    <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onRemoveBranch(row.branchId)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.branchId)}
                sx={{
                    '&:hover': {
                        color: 'action.active',
                        cursor: 'pointer',
                    },
                }}>
                {row.branchName}
            </Link>
        )
    }

    const hideDialogAndRefreshBranchList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Branch data removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onRemoveBranch = async (branchId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteBranch({ id: companyConfig.companyId, branchId: branchId }))
                hideDialogAndRefreshBranchList()
            },
            onNo: hideDialog,
        })
    }

    const onPublishBranch = async (branchId) => {
        try {
            await branchServices.publishBranch({ branchId })
            showDialog({
                variant: 'confirm',
                title: 'This will publish and upgrade database, do you want to continue?',
                onYes: () => {
                    hideDialogAndRefreshBranchList()
                },
                onNo: hideDialog,
            })
        } catch (error) {
            enqueueSnackbar(error.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const columns = [
        { name: 'branchName', title: 'Branch Name', getCellValue: renderLinkCell },
        { name: 'address', title: 'Address' },
        { name: 'phoneNo', title: 'Phone Number' },
        { name: 'licenseStartDate', title: 'License Start Date' },
        { name: 'licenseEndDate', title: 'License End Date' },
        { name: 'branchType', title: 'Branch Type' },
        { name: 'reportXClientId', title: 'Report Client Id' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidths = useMemo(
        () => [
            { columnName: 'branchName', width: min ? '150px' : '18%', wordWrapEnabled: true, minWidth: '150px' },
            { columnName: 'address', width: min ? '120px' : '15%', wordWrapEnabled: true, minWidth: '110px' },
            { columnName: 'phoneNo', width: min ? '150px' : '22%', minWidth: '150px' },
            { columnName: 'licenseStartDate', width: min ? '190px' : '24%', wordWrapEnabled: true, minWidth: '190px' },
            { columnName: 'licenseEndDate', width: min ? '190px' : '17%', wordWrapEnabled: true, minWidth: '190px' },
            { columnName: 'branchType', width: min ? '140px' : '15%', wordWrapEnabled: true, minWidth: '140px' },
            { columnName: 'reportXClientId', width: min ? '150px' : '20%', minWidth: '150px', wordWrapEnabled: true },
            {
                columnName: 'Action',
                width: min ? '90px' : '15%',
                wordWrapEnabled: true,
                minWidth: '90px',
                align: 'center',
            },
        ],
        [min]
    )

    const sortingColumnExtensions = [
        { columnName: 'reportXClientId', sortingEnabled: false },
        { columnName: 'branchType', sortingEnabled: false },
        { columnName: 'Action', sortingEnabled: false },
    ]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={branches.data} columns={columns}>
                <DateTypeProvider for={dateColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={branches.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default BranchGridData
