import React from 'react'
import Divider from '@mui/material/Divider'
import { Grid } from '@mui/material'
import { Card, CardContent, ListItem, ListItemText, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import CategoryIcon from '@mui/icons-material/Category'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import PlaceIcon from '@mui/icons-material/Place'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import EmailIcon from '@mui/icons-material/Email'
import PersonIcon from '@mui/icons-material/Person'
import Span from '@jumbo/shared/Span'

const LeftInfoCard = ({ transaction }) => {
    return (
        <Grid>
            <Card sx={{ height: { xs: 'auto', md: 250 } }}>
                <CardContent sx={{ justifyContent: 'center' }}>
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item xs={12} sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <List sx={{ width: '100%', color: 'text.primary' }}>
                                <ListItem sx={{ mt: -2 }}>
                                    <ListItemText
                                        disablePadding
                                        primary={
                                            <Typography
                                                variant={'h5'}
                                                color='text.primary'
                                                fontFamily='Lobster, cursive'>
                                                <CategoryIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                                {transaction.txnCategoryName ? (
                                                    <>
                                                        <Span sx={{ color: 'secondary.dark' }}>
                                                            {' '}
                                                            {transaction.txnCategoryName}{' '}
                                                        </Span>
                                                        Category
                                                    </>
                                                ) : (
                                                    <>
                                                        <Span sx={{ color: 'secondary.dark' }}> No </Span>
                                                        Category
                                                    </>
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem sx={{ mt: -1 }}>
                                    <ListItemText disablePadding>
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <PointOfSaleIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                            Sale Type is
                                            <Span sx={{ color: 'secondary.dark' }}> {transaction.saleType} </Span>{' '}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{ mt: -1 }}>
                                    <ListItemText disablePadding>
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <HowToRegIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                            Check Out By{' '}
                                            <Span sx={{ color: 'secondary.dark' }}>
                                                {' '}
                                                {(transaction?.checkOutFByirstName ?? '') +
                                                    ' ' +
                                                    (transaction?.checkOutFByLastName ?? '')}{' '}
                                            </Span>
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{ mt: -1 }}>
                                    <ListItemText disablePadding>
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <PersonIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                            Served By{' '}
                                            <Span sx={{ color: 'secondary.dark' }}>
                                                {`${transaction?.servedFByFirstName ?? ''} ${
                                                    transaction?.servedFByLastName ?? ''
                                                }`}
                                            </Span>
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid
                            item
                            sx={{ width: '100%', display: 'flex', alignItems: 'center', ml: { xs: 2, md: 3.9 } }}>
                            {' '}
                            <List sx={{ width: '100%' }}>
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        {' '}
                                        <AccountBoxIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />{' '}
                                        {transaction.customerFirstName && !transaction.customerLastName
                                            ? transaction.customerFirstName
                                            : !transaction.customerFirstName && transaction.customerLastName
                                            ? transaction.customerLastName
                                            : transaction.customerFirstName && transaction.customerLastName
                                            ? `${transaction.customerFirstName} ${transaction.customerLastName}`
                                            : ''}
                                    </Typography>
                                </ListItemText>

                                <Divider sx={{ mb: 2 }} />
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <PlaceIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        {transaction.customerAddress}
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2 }} />
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        {' '}
                                        <EmailIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        {transaction.customerEmail}
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2 }} />
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <ContactPhoneIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                        {transaction.customerMobileNo}
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2 }} />
                            </List>
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default LeftInfoCard
