import React from 'react'
import Div from '@jumbo/shared/Div'
import JumboContentLayout from '@jumbo/components/JumboContentLayout'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid, useMediaQuery } from '@mui/material'
import BranchGrid from './components/branches/BranchGrid'
import CompanyGrid from './components/companyGridSidebar/CompanyGrid'
import { useJumboTheme } from '@jumbo/hooks'

const Branches = () => {
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md
    return (
        <JumboCardQuick
            noWrapper
            headerSx={{
                height: 40,
                bgcolor: 'secondary.main',
                pt: 3.2,
            }}>
            <Div>
                {min ? (
                    <Grid container direction='column' spacing={2}>
                        <Grid item sx={{ width: '100%' }}>
                            <CompanyGrid />
                        </Grid>
                        <Grid item sx={{ width: '100%' }}>
                            <BranchGrid />
                        </Grid>
                    </Grid>
                ) : (
                    <JumboContentLayout
                        sidebar={
                            <Grid>
                                <CompanyGrid />
                            </Grid>
                        }
                        layoutOptions={{
                            sidebar: {
                                sx: {
                                    mr: 1,
                                    width: '30%',
                                },
                            },
                        }}>
                        <Grid sx={{ mb: 1.5 }}>
                            <BranchGrid />
                        </Grid>
                    </JumboContentLayout>
                )}
            </Div>
        </JumboCardQuick>
    )
}

export default Branches
