import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import PaidSharpIcon from '@mui/icons-material/PaidSharp'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
    ColumnChooser,
    Toolbar,
} from '@devexpress/dx-react-grid-material-ui'
import { useDispatch, useSelector } from 'react-redux'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useSnackbar } from 'notistack'
import { deleteCustomer, getCustomerById } from 'app/redux/store/features/customers'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import { enforceMinWidths, recalculateWidths } from 'app/utils/components/ColumnWidth/ColumnWidth'
import useJumboTheme from '../../../../../../../@jumbo/hooks/useJumboTheme'

const CustomerGridData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
    columnChooserOpen,
    setColumnChooserOpen,
    btnRef,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { showDialog, hideDialog } = useJumboDialog()
    const { customers, loading, error } = useSelector((state) => state.customers)
    const dateColumns = ['syncDate']
    const [sorting, setSorting] = useState([{ columnName: 'firstName', direction: 'asc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    const [hiddenColumns, setHiddenColumns] = useState([])
    const gridContainerRef = useRef(null)
    const targetDiv = useRef(null)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getCustomerById({ customerId: id }))
        navigate(`/store/customers/edit/${id}`, { state: { customerId: id } })
    }

    const onNavigatingToPayment = (id) => {
        dispatch(getCustomerById({ customerId: id }))
        navigate(`/store/customers/draftpayments/addpayment`, { state: { customerId: id } })
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.customerId)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => onNavigatingToPayment(row.customerId)}>
                    <PaidSharpIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onNavigatingToDetail(row.customerId)}>
                    <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onRemoveCustomer(row.customerId)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.customerId)}
                sx={{
                    '&:hover': {
                        color: 'action.active', // Change this to the color you want on hover
                        cursor: 'pointer',
                    },
                }}>
                {row.customerCode}
            </Link>
        )
    }

    const hideDialogAndRefreshUserList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Customer removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onRemoveCustomer = async (customerId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteCustomer({ customerId: customerId }))
                hideDialogAndRefreshUserList()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'customerCode', title: 'Cust-Code', getCellValue: renderLinkCell },
        { name: 'firstName', title: 'First Name' },
        { name: 'lastName', title: 'Last Name' },
        { name: 'email', title: 'Email' },
        { name: 'mobileNo', title: 'Mobile No' },
        {
            name: 'balanceAmount',
            title: 'Balance',
            getCellValue: (row) => formatCurrency(row.balanceAmount),
        },
        { name: 'syncDate', title: 'Sync Date' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const defaultColumnWidths = useMemo(
        () => [
            { columnName: 'customerCode', width: min ? 110 : 170, minWidth: 110, wordWrapEnabled: true },
            { columnName: 'firstName', wordWrapEnabled: true, width: min ? 130 : 200, minWidth: 130 },
            { columnName: 'lastName', wordWrapEnabled: true, width: min ? 130 : 200, minWidth: 130 },
            { columnName: 'email', wordWrapEnabled: true, width: min ? 210 : 280, minWidth: 210 },
            { columnName: 'mobileNo', wordWrapEnabled: true, width: min ? 120 : 200, minWidth: 120 },
            {
                columnName: 'balanceAmount',
                align: 'right',
                wordWrapEnabled: true,
                width: min ? 100 : 150,
                minWidth: 100,
            },
            { columnName: 'syncDate', width: min ? 130 : 210, minWidth: 130, wordWrapEnabled: true },
            { columnName: 'Action', align: 'center', width: min ? 120 : 150, minWidth: 120 },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(defaultColumnWidths)

    useEffect(() => {
        if (gridContainerRef.current && columnChooserOpen) {
            gridContainerRef.current.click()
        }
        if (hiddenColumns.length === 0) {
            setColumnWidths(defaultColumnWidths)
        } else {
            const containerWidth = targetDiv.current ? targetDiv.current.offsetWidth : 0
            const recalculated = recalculateWidths(columnWidths, containerWidth, hiddenColumns)
            setColumnWidths(enforceMinWidths(recalculated))
        }
    }, [hiddenColumns, defaultColumnWidths, columnChooserOpen])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]
    const OverlayComponent = (props) => <ColumnChooser.Overlay {...props} target={targetDiv.current} />
    const handleColumnWidthsChange = (newWidths) => {
        const enforcedWidths = enforceMinWidths(newWidths)
        setColumnWidths(enforcedWidths)
    }

    const HiddenToolbar = (props) => {
        return <div {...props} style={{ display: 'none' }}></div>
    }

    const ToggleButton = useCallback(
        (props) => (
            <IconButton
                onClick={() => {
                    setColumnChooserOpen((prev) => !prev)
                    props.onToggle()
                }}
                buttonref={btnRef.current}
                ref={gridContainerRef}></IconButton>
        ),
        [setColumnChooserOpen, btnRef, gridContainerRef]
    )
    return (
        <Paper style={{ position: 'relative' }}>
            <div ref={targetDiv}></div>
            <Grid rows={customers.data} columns={columns}>
                <DateTypeProvider for={dateColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={customers.totalCount} />
                <Table columnExtensions={defaultColumnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={handleColumnWidthsChange}
                    defaultColumnWidths={defaultColumnWidths}
                    resizingMode='nextColumn'
                />
                <TableColumnVisibility hiddenColumnNames={hiddenColumns} onHiddenColumnNamesChange={setHiddenColumns} />
                <Toolbar rootComponent={HiddenToolbar} />
                <ColumnChooser overlayComponent={OverlayComponent} toggleButtonComponent={ToggleButton} />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default CustomerGridData
