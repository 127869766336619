import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import JumboContentLayout from '@jumbo/components/JumboContentLayout'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import UserPermissionsGrid from './components/permissions/UserPermissionsGrid'
import { updateUserTypeConfig } from 'app/redux/store/features/users'

const UserPermissions = (userId) => {
    const { userTypeName } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { userTypes, loading, error } = useSelector((state) => state.users)
    const userIdString = typeof userId === 'object' && userId !== null ? userId.userId : userId

    useEffect(() => {
        if (userTypeName) {
            userTypes.data.map((item) => {
                if (item.typeName.toLowerCase() === userTypeName) {
                    dispatch(updateUserTypeConfig({ id: item.id, readOnly: item.readOnly }))
                }
            })
        } else {
            dispatch(updateUserTypeConfig({ id: userTypes.data[0]?.id, readOnly: userTypes.data[0]?.readOnly }))
        }
    }, [userTypes])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    return (
        <JumboContentLayout>
            <UserPermissionsGrid handleError={handleError} userId={userIdString} />
        </JumboContentLayout>
    )
}

export default UserPermissions
