import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import UpdateUserGridForm from './UpdateUserForm'
import { useSnackbar } from 'notistack'
import { getUserById, selectItemById, updateUser } from '../../../../redux/store/features/users'
import { useDispatch, useSelector } from 'react-redux'
import utilServices from '../../../../services/util-services'
import LinkedCompanyBranchGrid from './LinkedCompanyBranch/LinkedCompanyBranch'
import { TabContext, TabPanel } from '@mui/lab'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import UserPermissions from './UserPermissions'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'

const UpdateUser = () => {
    const { authUser } = useJumboAuth()
    const [tabTitle, setTabTitle] = React.useState('details') // Initialize with a default value
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state } = useLocation()
    const { userId } = state
    const { users, loading, error } = useSelector((state) => state.users)
    const user = selectItemById(users, userId)
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(user.userId)) dispatch(getUserById({ userId: userId }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return

        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('User updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/security/users')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onTabSelectionChanged = (e, newValue) => {
        setTabTitle(newValue)
    }

    const onUpdateUserGrid = async () => {
        setApiCallInProgress(true)
        dispatch(updateUser({ ...user }))
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                noWrapper
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update User
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <TabContext value={tabTitle}>
                    <TabList sx={{ bgcolor: 'background.default', m: 2, mb: 0 }} onChange={onTabSelectionChanged}>
                        <Tab label={'Details'} value={'details'} sx={{ width: '160px' }} />
                        <Tab label={'Branches'} value={'branches'} sx={{ width: '160px' }} />
                        {!authUser?.isAdmin && (
                            <Tab label={'Permissions'} value={'permissions'} sx={{ width: '160px' }} />
                        )}
                    </TabList>
                    <TabPanel value='details'>
                        <UpdateUserGridForm userGrid={user} updateUserGrid={onUpdateUserGrid} />
                    </TabPanel>
                    <TabPanel value='branches'>
                        <LinkedCompanyBranchGrid userId={user.userId} />
                    </TabPanel>
                    {!authUser?.isAdmin && (
                        <TabPanel value='permissions'>
                            <UserPermissions userId={userId} />
                        </TabPanel>
                    )}
                </TabContext>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateUser
